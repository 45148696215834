@import url('https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap');

@font-face {
	font-family: 'Digital 7';
	src: url('./fonts/digital-7-mono.ttf');
}

* {
	box-sizing: border-box;
	font-family: 'Share Tech Mono', monospace;
}

body {
	margin: 0;
	padding: 0;
	background-color: #121212;
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
}

.calc-container {
	width: 330px;
	background-color: #0a0a0a;
	outline: 2px solid rgb(77, 77, 77);
}

// Calculator display

.display {
	height: fit-content;
}

.formula {
	font-size: 20px;
	color: #ffa500;
	padding: 5px;
	max-width: 100%;
	word-wrap: break-word;
}

.solution {
	font-size: 29px;
	padding: 0 5px;
	max-width: 100%;
	word-wrap: break-word;
}

.formula,
.solution {
	font-family: 'Digital 7';
	text-align: right;
	margin: 0;
}

.hidden {
	visibility: hidden;
}

// Calculator input

.input-container {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-template-rows: repeat(5, 1fr);
	height: 85%;
	padding: 5px;
	gap: 1px;
}

button {
	border: none;
	outline: none;
	color: #fff;
	font-size: 20px;
	transition: all 100ms ease-in-out;
	padding: 1.2em;

	&:hover {
		outline: 1px solid rgb(150, 150, 150);
		color: #121212;
	}

	&:active {
		transform: scale(0.98);
	}
}

.zero,
.clear {
	grid-column: 1 / 3;
}

.equal {
	grid-row: 4 / -1;
	grid-column: 4;
}

.add,
.subtract,
.multiply,
.divide {
	background: rgb(102, 102, 102);
}

.clear {
	background: rgb(172, 57, 57);
}

.equal {
	background: rgb(0, 68, 102);
}

.number-inputs {
	background: rgb(77, 77, 77);
}

.credits {
	text-align: center;

	a {
		color: rgb(0, 131, 197);
		text-decoration: none;
		position: relative;

		&::before {
			content: '';
			position: absolute;
			width: 100%;
			height: 2px;
			border-radius: 4px;
			background-color: rgb(0, 131, 197);
			bottom: -2px;
			left: 0;
			transform-origin: right;
			transform: scaleX(0);
			transition: transform 0.3s ease-in-out;
		}

		&:hover::before,
		&:focus::before {
			transform-origin: left;
			transform: scaleX(1);
		}

		&:active {
			color: rgb(0, 80, 140);
		}
	}
}
